// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { graphql } from 'gatsby';

import { RootContainer, SEOContainer } from '~containers';
import { Main } from '~components';
import { renderBlocks } from '~utils';

// ─────────────────────────────────────────────────────────────────────────────
// query
// ─────────────────────────────────────────────────────────────────────────────

export const query = graphql`
  query($path: String!) {
    page: mdx(frontmatter: { meta: { permalink: { eq: $path } } }) {
      frontmatter {
        ...MetaFragment
        blocks {
          type
          title
          subtitle
          body
          id
          buttons {
            title
            url
            look
          }
          icons {
            title
            body
            icon
          }
          heroImage {
            ...HeroImageFragment
          }
          sidekickImage {
            ...SidekickImageFragment
          }
          video
          filter
          layout
          timeline {
            title
            date
          }
          startups {
            title
            owner
            type
            industry
            description
            url
            position
          }
          cards {
            title
            markdown
          }
          mdx
        }
      }
    }
  }
`;

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function PageTemplate({
  location,
  data: {
    page: {
      frontmatter: { meta, blocks },
    },
  },
}) {
  return (
    <RootContainer location={location}>
      <SEOContainer meta={meta} />
      <Main gridTemplateColumns="var(--width-outside) 1fr var(--width-outside)" padding="10rem 0 0">
        {renderBlocks(blocks)}
      </Main>
    </RootContainer>
  );
}
